/*import Vue from 'vue'
import Router from 'vue-router'

// 导入组件 
import Search from '../search/search'
import Order from './modules/order'
import Showinfo from '../showinfo/showinfo'
import Index from '../index'

Vue.use(Router)
 
// 创建一个路由器
export default new Router({
    routes: [
	  {
	    name: 'search',
        path: '/search',
        component: Search
      },
	  {
	    name: 'index',
        path: '/',
        component: Index
      },
	  {
	    name: 'showinfo',
        path: '/showinfo',
        component: Showinfo
      },
	  {
	    name: 'order',
        path: '/order',
        component: Order
      },
    ]

})*/
import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index'   // 重定向，输入/的时候直接进入首页
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import('../views/login.vue')
  // },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/index.vue')
  },
  {
    path: '/showinfo',
    name: 'showinfo',
    component: () => import('../views/showinfo.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../search/search.vue')
  },
  {
      path: '/orderlist',
      name: 'orderlist',
      component: () => import('../views/order/orderlist.vue')
  },
  {
      path: '/myorderlist/',
      name: 'myorderlist',
      component: () => import('../views/order/orderlist.vue')
  },
  {
      path: '/orderinfo',
      name: 'orderinfo',
      component: () => import('../views/order/orderinfo.vue')
    },

]

const router = new VueRouter({
	mode: 'history',
  routes
})

export default router


