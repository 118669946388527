import Vue from 'vue'
import App from './App.vue'
import router from './router'
Vue.config.productionTip = false

//import { Button, Tab, Tabs, Col, Row, Form, Field, Popup, DatetimePicker, Area, Picker, Uploader, Dialog, Toast, Loading, Cell, CellGroup, Search, Icon, NavBar, Image as VanImage, List } from 'vant'
import Vant from 'vant'
import 'vant/lib/index.css'
import 'vant/lib/index.less'

/*Vue.use(Button)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Col)
Vue.use(Row)
Vue.use(Form)
Vue.use(Field)
Vue.use(Popup)
Vue.use(DatetimePicker)
Vue.use(Area)
Vue.use(Picker)
Vue.use(Uploader)
Vue.use(Dialog)
Vue.use(Toast)
Vue.use(Loading)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(router)
Vue.use(Search)
Vue.use(Icon)
Vue.use(NavBar)
Vue.use(List)
Vue.use(VanImage)*/
Vue.use(Vant)

/*new Vue({
  render: h => h(App)
}).$mount('#app')*/


new Vue({
  el: '#app',
  router,
  components: { App },
  render: h => h(App)
})
